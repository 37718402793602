// -----------------------------------
// Panel panes - Content Region
// -----------------------------------
.panel-pane {
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }
  .panel-pane {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Panel Pabe Title
// ---------------------
.pane-title {
  margin-bottom: 1.5rem;
  font-size: .8rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  &:after,
  &:before {
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid $theme-gray-light;
    position: absolute;
    bottom: calc(50% + 1.5px);
    z-index: 0;
  }
  &:before {
    bottom: calc(50% - 1.5px);
  }
  .title-text {
    display: inline-block;
    vertical-align: middle;
    background: $body-bg;
    padding: 2px 8px 2px;
    color: $theme-gray-dark;
    position: relative;
    z-index: 1;
  }
}

// -----------------------------------
// Panel panes - Footer Region
// -----------------------------------
.region-footer {
  .panel-pane {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .pane-title .title-text {
    background: $theme-gray-lightest;
  }
}

// ----------------------
// Views Panel Pane
// ----------------------
// More Link
.more-link {
  margin-top: 1rem;
  text-align: center;
  &:after {
    content: "";
    display: block;
    border-top: 1px dashed $theme-gray-lighter;
    position: relative;
    bottom: 10px;
    z-index: 0;
  }
  a {
    display: inline-block;
    background: #FFF;
    padding: 2px 10px 2px 10px;
    color: $theme-gray-light;
    font-size: .8rem;
    text-decoration: none;
    position: relative;
    z-index: 1;
  }
}
